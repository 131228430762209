
import {onMounted, ref, watch} from "vue";
import useMeasurementUnit from "@/modules/inventory_configuration/composables/useMeasurementUnit";
import usePanel from "@/composables/usePanel";

const {measurementUnitForm} = useMeasurementUnit();

export default {
    props: ['catalogs'],
    setup() {
        const {showPanel, togglePanel} = usePanel()
        const name = ref('')
        const description = ref('')
        const status = ref('')
        onMounted(() => {
            name.value = measurementUnitForm.value.name
            description.value = measurementUnitForm.value.description
            status.value = measurementUnitForm.value.status
        })
        watch(name, val => measurementUnitForm.value.name = val)
        watch(description, val => measurementUnitForm.value.description = val)
        watch(status, val => measurementUnitForm.value.status = val)
        watch(measurementUnitForm, val => {
            name.value = val.name
            description.value = val.description
            status.value = val.status
        })
        return {
            showPanel,
            togglePanel,
            name,
            description,
            status,
            measurementUnitForm
        }
    }
}
